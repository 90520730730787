@charset "utf-8";
/* CSS Document */

@import url('https://maxcdn.bootstrapcdn.com/bootstrap/4.5.2/css/bootstrap.min.css');
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css');
 
@font-face {
    font-family: 'Alternate Gothic No3 D';
    src: url('../font/AlternateGotNo3D.eot');
    src: url('../font/AlternateGotNo3D.eot?#iefix') format('embedded-opentype'),
        url('../font/AlternateGotNo3D.woff2') format('woff2'),
        url('../font/AlternateGotNo3D.woff') format('woff'),
        url('../font/AlternateGotNo3D.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Alternate Gothic No1 D';
    src: url('../font/AlternateGotNo1D.eot');
    src: url('../font/AlternateGotNo1D.eot?#iefix') format('embedded-opentype'),
        url('../font/AlternateGotNo1D.woff2') format('woff2'),
        url('../font/AlternateGotNo1D.woff') format('woff'),
        url('../font/AlternateGotNo1D.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

body {
    margin: 0;
    padding: 0;
    padding-bottom: 0;
    font-family: 'Alternate Gothic No3 D', sans-serif !important;
    font-size: 14px;
    color: #3a3a3a;
    font-weight: 400;
    position: relative;
    background-color: #181818 !important;
} 

*  { 
    margin: 0px;
    padding: 0px;
    box-sizing: border-box; 
}

.clearfix {
    zoom: 1;
}

.clearfix:before,
.clearfix:after {
    display: table;
    content: "";
    line-height: 0;
}

.clearfix:after {
    clear: both;
}

.clr,
.clear {
    clear: both;
}

header,
footer,
aside,
article,
section,
nav,
figure {
    position: relative;
    
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
    margin: 0;
    padding: 0;
}

ul,
li,
ol {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

a {
    text-decoration: none;
    outline: none !important;
    cursor: pointer;
}

a:hover,
a:focus {
    text-decoration: none !important;
    outline: none;
}

img {
    border: 0;
    outline: none;
    max-width: 100%;
}

input[type="text"],
input[type="password"],
select,
textarea,
input[type="button"] {
    max-width: 100%;
}

input[type=date].form-control{ text-transform: uppercase; }
     
table {  width: 100%; }
p { margin: 0; padding: 0; }
.fltL { float: left; }
.fltR { float: right !important; }
.padding_none { padding: 0 !important;}
.border_none{border:none !important}
.navbar-toggle {  background-color: #fff !important; }
.icon-bar { background-color: #000 !important; }

.Switch{cursor:pointer;margin: 0;}
.Switch input{opacity:0;width:0;height:0;position: absolute;}
.Switch .slider{cursor:pointer;background-color: transparent;-webkit-transition:0.4s;transition:0.4s;height: 23px;border-radius:50px;width: 43px;border: 1px solid #ffffff;min-width: auto;display: block;position: relative;}
.Switch .slider:before{position:absolute;content:"";height:13px;width:13px;left: 5px;bottom:0;top:0;background-color: #428BF9;-webkit-transition:0.4s;transition:0.4s;border-radius:50%;margin:auto;}
.Switch input:checked+.slider:before{-webkit-transform:translateX(19px);transform:translateX(19px);background: #428BF9;}

.Radio{display:block;position:relative;padding-left:35px;cursor:pointer;font-size:22px;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none;margin: 0 !important;color: #6D6D6D !important;}
.Radio input{position:absolute;opacity:0;cursor:pointer}
.Radio .checkmark{position:absolute;top: 2px;left:0;height: 20px;width: 20px;background-color: transparent;border-radius:50%;border: 1px solid #ffffff;}
.Radio .checkmark:after{content:"";position:absolute;display:none}
.Radio input:checked~.checkmark:after{display:block}
.Radio .checkmark:after{width: 10px;height: 10px;border-radius:50%;background:#fff;top:0;left:0;bottom:0;right:0;margin:auto}

header{padding: 30px 50px 0;position: absolute;top: 0;left: 0;z-index: 99;width: 100%;z-index: 999999;}
header .navbar{background-color: #000;padding: 15px 60px;border-radius: 10px;}
header .navbar .navbar-brand{padding:0;max-width: 100px;margin: 0;}
header .navbar .navbar-collapse{justify-content: end;}
header .navbar .navbar-nav li{margin: 0 0 0 45px;}
header .navbar .navbar-nav li .nav-link{padding:0;font-size: 20px;font-weight: 500;color: #ffffff;position: relative;}
header .navbar .navbar-nav li .nav-link:hover{color: #428BF9;}
header .navbar .navbar-nav li.active .nav-link{color: #428BF9;}

.SlideArea{padding: 125px 50px 50px 50px;}
.SlideArea .SlideHead{margin:0 0 30px 0;text-align:center}
.SlideArea .SlideHead .nav-tabs{border:none;display:inline-flex;background-color:#000;border-radius:10px;position: relative;z-index: 999;}
.SlideArea .SlideHead .nav-tabs .nav-item{margin:0}
.SlideArea .SlideHead .nav-tabs .nav-item .nav-link{width:175px;border:1px solid #000;border-radius:10px;color:#595959;font-size:20px}
.SlideArea .SlideHead .nav-tabs .nav-item .nav-link.active{background-color:transparent;border-color:#fff;color:#fff}
/* .SlideArea .SlideBox{display:flex; align-items: baseline;} */
.SlideArea .SlideBox {display: flex; grid-template-columns: 60% 40%; align-items: baseline;}
.SlideArea .SlideLeft{width:100%;padding:0 45px 0 15px;position:relative}
.SlideArea .SlideLeft figure{width:100%;margin:auto;display: flex; justify-content: center;}
.SlideArea .SlideLeft figure img{width:82%}
.SlideArea .SlideLeft figcaption{height:80px;margin:0;position:relative}
.SlideArea .SlideLeft figcaption:before{position:absolute;width:100%;height:200px;border:2px dashed #9B9B9B;border-radius:50%;bottom:0;left:0}
.SlideArea .SlideLeft figcaption span.Line{display:block;height:80px;overflow:hidden;position:relative;}
.SlideArea .SlideLeft figcaption span.Line:before{content:'';content:'';position:absolute;width:100%;height:200px;border-radius:50%;bottom:0;left:0}
.SlideArea .SlideLeft figcaption span.Line1:before{border:2px dashed #496def;}
.SlideArea .SlideLeft figcaption span.Line2:before{border:2px dashed #ff4700;}
.SlideArea .SlideLeft figcaption span.Line3:before{border:2px dashed red;}
.SlideArea .SlideLeft figcaption span.Line4:before{border:2px dashed #496def;}

.ExpandWidth{width: 100%!important;}




.SlideArea .SlideLeft figcaption span.Circle{position:absolute;width:25px;height:25px;;border-radius:50%;text-align:center;color:#fff;font-size:23px;font-family:'Alternate Gothic No1 D';left:0;right:0;margin:auto;bottom:-12px;line-height:50px}
.SlideArea .SlideLeft figcaption span.Circle:before{content:'';position:absolute;width:40px;height:40px;left:-7px;top:-7px;border-radius:50%}
.SlideArea .SlideLeft figcaption span.Circle1{position:absolute;width:25px;height:25px;background-color:#496def;border-radius:50%;text-align:center;color:#fff;font-size:23px;font-family:'Alternate Gothic No1 D';left:0;right:0;margin:auto;bottom:-12px;line-height:50px}
.SlideArea .SlideLeft figcaption span.Circle1:before{content:'';position:absolute;width:40px;height:40px;border:2px dashed #496def;left:-7px;top:-7px;border-radius:50%}
.SlideArea .SlideLeft figcaption span.Circle2{position:absolute;width:25px;height:25px;background-color:#ff4700;border-radius:50%;text-align:center;color:#fff;font-size:23px;font-family:'Alternate Gothic No1 D';left:0;right:0;margin:auto;bottom:-12px;line-height:50px}
.SlideArea .SlideLeft figcaption span.Circle2:before{content:'';position:absolute;width:40px;height:40px;border:2px dashed #ff4700;left:-7px;top:-7px;border-radius:50%}
.SlideArea .SlideLeft figcaption span.Circle3{position:absolute;width:25px;height:25px;background-color:red;border-radius:50%;text-align:center;color:#fff;font-size:23px;font-family:'Alternate Gothic No1 D';left:0;right:0;margin:auto;bottom:-12px;line-height:50px}
.SlideArea .SlideLeft figcaption span.Circle3:before{content:'';position:absolute;width:40px;height:40px;border:2px dashed red;left:-7px;top:-7px;border-radius:50%}
.SlideArea .SlideLeft figcaption span.Circle4{position:absolute;width:25px;height:25px;background-color:#496def;border-radius:50%;text-align:center;color:#fff;font-size:23px;font-family:'Alternate Gothic No1 D';left:0;right:0;margin:auto;bottom:-12px;line-height:50px}
.SlideArea .SlideLeft figcaption span.Circle4:before{content:'';position:absolute;width:40px;height:40px;border:2px dashed #496def;left:-7px;top:-7px;border-radius:50%}


.SlideArea .SlideLeft figcaption span.Circle sup{font-size:12px;top:-10px;left:-2px}
.SlideArea .SlideLeft .ExpandBox{position:absolute;top:30%;right:30px;z-index: 99;}
.SlideArea .SlideLeft .ExpandBox a{display:inline-flex;align-items:center;color:#fff;font-size:20px}
.SlideArea .SlideLeft .ExpandBox a span.Icon{display:flex;width:25px;height:25px;margin:0 10px 0 0}
.SlideArea .SlideLeft .ExpandBox a span.Text:before{content:attr(data-label)}
.SlideArea .SlideRight{position:relative;border-radius:25px;overflow:hidden;width: 75%;}.hide{display:none}.show{display:inline-block}
.SlideArea .SlideRight video{width:100%}
.SlideArea .SlideRight:before{content:'';position:absolute;background:linear-gradient(360deg,#000000 0%,rgba(0,0,0,.426912) 31.39%,rgba(255,255,255,0) 100%);border-radius:28px;top:0;left:0;width:100%;height:100%}
.SlideArea .SlideRight article{position:absolute;bottom:0;padding:20px;color:#fff}
.SlideArea .SlideRight article h4{font-size:30px;margin:0 0 5px 0}
.SlideArea .SlideRight article p{color:gray;font-size:17px}


.SlideRight{position:relative;border-radius:25px;overflow:hidden;width:100%}.hide{display:none}.show{display:inline-block}
.SlideRight video{width:100%}
.SlideRight:before{content:'';position:absolute;background:linear-gradient(360deg,#000000 0%,rgba(0,0,0,.426912) 31.39%,rgba(255,255,255,0) 100%);border-radius:28px;top:0;left:0;width:100%;height:100%}
.SlideRight article{position:absolute;bottom:0;padding:20px;color:#fff}
.SlideRight article h4{font-size:30px;margin:0 0 5px 0}
.SlideRight article p{color:gray;font-size:17px}




.SlideArea .SlideRight .SlideRightOverlay {position: absolute; top: 20px; right: 20px;cursor: pointer; z-index: 9999!important;} 
.SlideArea .SlideRight .SlideRightOverlay figure {width: 24px;}
.VideoModal {z-index: 9999;}
.SlideRightOverlay [type="button"]{background-color: transparent; border: none; outline: none;}
.modal-body {padding: 0px;}
.modal-content {background: transparent!important;}
.modal-body video {width: 100%;}
.modal-body .CloseModal {position: absolute;top: -5px;right: -5px; z-index: 99; width: 26px;height: 26px;background-color: #fff;text-align: center;color: #000;font-size: 15px;border-radius: 50%;opacity: 1;}
.modal-body .CloseModal span {display: inline-block; width: 80%;}

.SpecificationsArea{padding: 85px 0px 50px;}
.SpecificationsArea .SpecificationsBox{background-color:#000;padding:50px 150px;border-radius:10px}
.SpecificationsArea .SpecificationsBox .Specifications{color:#fff}
.SpecificationsArea .SpecificationsBox .Specifications h4{font-size:35px;margin:0 0 15px 0}
.SpecificationsArea .SpecificationsBox .Specifications p{font-size:18px;margin:0 0 0px 0;font-weight:400}
.SpecificationsArea .SpecificationsBox .Specifications p label{margin:0;display:inline-block;width:175px;line-height:22px}
.SpecificationsArea .SpecificationsBox .Specifications p span{color:#6D6D6D}

footer{padding:60px 0 70px 0}
footer:before{content:'';
    /* background-image:url(../images/Footer.png); */
    position:absolute;top:0;left:0;width:100%;height:100%;background-position:0 -10px;background-size:cover;opacity:.05}
footer .Footer{margin:0 0 20px 0}
footer .Footer .Foots{margin:0 0 30px 0}
footer .Footer .Foots img{margin:0 0 15px 0}
footer .Footer .Foots p{color:#6D6D6D;font-size:18px;line-height:26px}
footer .Footer .Foots h4{color:#fff;font-size:22px;margin:0 0 15px 0}
footer .Footer .Foots ul li{display:inline-block;margin:0 15px 0 0}
footer .Footer .Foots ul li a{background-color:#6D6D6D;width:40px;height:40px;display:block;text-align:center;color:#fff;border-radius:50px;line-height:40px}
footer .CopyRight{text-align:center;position:relative}
footer .CopyRight p{color:#6D6D6D;font-size:20px}
footer .Footer .FooterList h4{color:#fff;font-size:22px;margin:0 0 15px 0}
footer .Footer .FooterList ul li a {color:#6D6D6D; font-size:18px;}

.Breadcumb{background-image: url('../images/banner2.jpg');padding: 330px 70px 50px 70px;background-size: cover;position: relative;z-index: 9;background-position: top;}
.Breadcumb article{max-width: 525px; padding: 15px;}
.BreadcumbBg article {background-color:rgba(0, 0, 0, 0.6);}
.Breadcumb article h4{color: #fff;font-size: 55px;margin: 0 0 10px 0;}
.Breadcumb article p{color: #ddd;font-size: 18px; font-family: system-ui;}

@media (max-width:576px){
    .Breadcumb article p {
        font-size: 14px;
    }
}
.PartnerArea{ padding:50px 0px}
.PartnerArea .PartnerHead{text-align: center; margin: 0 0 30px 0;}
.PartnerArea .PartnerHead ol{display: flex;justify-content: center;}
.PartnerArea .PartnerHead ol li{display: inline-block;margin: 0 10px;color: #fff;font-size: 18px;line-height: 20px;}
.PartnerArea .PartnerBody article{text-align:center;margin-bottom: 45px;}
.PartnerArea .PartnerBody article .nav-tabs{border:none;display:inline-flex;background-color:#000;border-radius:10px;position:relative; z-index: 99}
.PartnerArea .PartnerBody article .nav-tabs .nav-item{margin:0}
.PartnerArea .PartnerBody article .nav-tabs .nav-item .nav-link{width: 315px;border:1px solid #000;border-radius:10px;color:#595959;font-size:20px;text-align: center;}
.PartnerArea .PartnerBody article .nav-tabs .nav-item .nav-link.active{background-color:transparent;border-color:#fff;color:#fff}

.CommonForm  {font-size: 16px;padding: 50px 30px;background-color: #000000;font-weight: 700;color: #000;margin: 0 0 20px 0px;}
.CommonForm .form-group{margin: 0 0 30px 0;}
.CommonForm .form-group .form-group{margin:0 0 0 0}
.CommonForm .form-group label{display:block;color: #fff!important;font-weight: 500;font-size: 18px;text-transform:capitalize;margin:0 0 10px 0}
.CommonForm .form-group .form-control{height:45px;color: #fff; padding: 12px 10px 12px 15px;border: 1px solid #ffffff;border-radius:5px;box-shadow:none;font-weight: 400;font-size: 16px;background-color: transparent;letter-spacing: 0.5px;}
.CommonForm .form-group .form-control::placeholder { color: #fff;opacity: 1; }
.CommonForm .form-group .form-control:-ms-input-placeholder { color: #fff;}
.CommonForm .form-group .form-control::-ms-input-placeholder { color: #fff;}
.CommonForm .form-group select option {color: #000; font-family: system-ui}

.CommonForm-india a {position: relative;z-index: 99;}
/* .CommonForm .form-group ul{} */
.CommonForm .form-group ul li{display: inline-block;width: 32%; margin-top: 10px;}
.CommonForm button{width: 100%;background-color: #428BF9;border: none;color: #fff;font-size: 20px;letter-spacing: 0.5px;padding: 10px 0;border-radius: 5px;}


.SlideShape{position: absolute;right: 0;top: 0;width: 700px;}
.SpecificationsLeftShape{position: absolute;left: 0;top: -100%;width: 625px;}
.SpecificationsRightShape{ position: absolute;right: 0;top: -30%;width: 625px;}
.PartnerLeftShape{position: absolute;left: 0;top: -40%;width: 500px;}
.PartnerRightShape{position: absolute;right: 0;top: -10%;width: 425px;}







input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.VideoModal .modal-dialog{ max-width: 800px; margin: 100px auto 0; }

.RadioWrap {display: flex; justify-content: space-between; flex-wrap: wrap;}



.CommonForm-india .india-ride{
    border: 1px solid black;
    padding: 10px;
    border-radius: 20px;
    background: black;
    margin: 0px 0px 25px 0px;
    height: calc(100% - 25px);
}
.CommonForm-india .india-ride figcaption h3{
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 28px;
    color: #FFFFFF;
}
.CommonForm-india .india-ride figcaption p{
    font-weight: 400;
    font-size: 12px;
    line-height: 36px;
    color: #6D6D6D;
}
.CommonForm-india .india-ride figcaption span img{padding: 15px 0 10px;width: 100%;}

.Breadcumb2{background-image: url('../images/banner2.jpg');padding: 400px 70px 50px 70px;background-size: cover;position: relative;z-index: 9;}
.Breadcumb2 article{max-width: 525px;}
.Breadcumb2 article h4{color: #fff;font-size: 55px;margin: 0 0 10px 0;}
.Breadcumb2 article p{color: #6D6D6D;font-size: 20px;}
.BreadcumbRide {padding: 225px 70px 50px 70px;}


.about-body{
    padding: 0px;
    border-radius: 10px;
}
.about-body ul{display: flex;}
.about-body ul aside{width: 50%;}
.about-body ul aside .about-left{padding: 60px;}
.about-body ul aside .Experience{padding: 60px 125px 60px 0px;}
.about-body ul figure{width: 50%;margin: 0;}

.about-body .about-left h2{
    font-weight: 400;
    font-size: 32px;
    line-height: 60px;
    color: #FFFFFF;
    letter-spacing: .5px;
}
.about-body .about-left p{
    font-weight: 400;
    font-size: 22px;
    line-height: 32px;
    color: #6D6D6D;
    padding: 20px 0px 0px 0px;
}
.about-body .about-left h3{
    font-size: 40px;
    line-height: 90px;
    color: #D1D1D1;
    letter-spacing: .5px;
}
.missions{text-align: right;}

.about-body .missions p{padding: 0px;}

.values{
    margin: auto;
    padding: 0px 150px;
}
.values h2{
    font-weight: 400;
    font-size: 55px;
    line-height: 96px;
    color: #FFFFFF;
    text-align: center;
    letter-spacing: .5px;
}
.values ul{
    padding-top: 20px;
}
.values ul li{
    font-weight: 400;
    font-size: 26px;
    line-height: 33px;
    color: #6D6D6D;
    list-style: disc;
}
.values p{
    font-weight: 400;
    font-size: 26px;
    line-height: 33px;
    color: #6D6D6D;
}

.SlideArea .slide-video{width: 100%;margin: auto;}
.SlideArea .slide-video article{bottom: 20px;}
.CommonForm-video{background: rgba(66, 139, 249, 0.03);border-radius: 10px;}
.CommonForm-video aside .missions{padding: 130px 0px 70px 100px;border-radius: 10px;}
.CommonForm-video aside .missions h2{
    line-height: 40px;
    padding-bottom: 40px;
    font-weight: 400;
    font-size: 32px;
    color: #FFFFFF;
    letter-spacing: .5px;
}

.CommonForm-video ul aside{
    padding: 0px 0px 0px 0px;
}
.CommonForm-video aside .missions p{
    font-weight: 400;
    font-size: 22px;
    line-height: 32px;
    color: #6D6D6D;
}
.CommonForm-video ul{padding: 20px 60px;}

.video-left {
    width: 50%;
    position: relative;
    overflow: hidden;
    margin: 0px 0 0 0;
}
.video-left video{
    height: 500px;
    position: absolute;
    left: -350px;
}

.video-right{
    width: 50%;
    position: relative;
    overflow: hidden;
    margin: 10px 0 -127px 0;
}
.video-right video{
    height: 500px;
    position: absolute;
    right: -230px;
}
/* .SpecificationsArea2{
    margin: 0px 60px;
} */

header .navbar .navbar-nav .nav-link:focus{color: #428BF9;}



@media (max-width:1200px){
    .SpecificationsArea .SpecificationsBox {padding: 50px 50px;}
    .about-body ul aside .about-left {padding: 25px;}
    .about-body .about-left p {padding: 0;}
}

@media (max-width:992px){
    header {width: 100%;background-color: #000;padding: 0px;}
    .navbar-light .navbar-toggler {background-color: #fff;}
    .SpecificationsArea .SpecificationsBox {padding: 25px 50px;}
    .SpecificationsArea .SpecificationsBox .Specifications {margin: 0 0 25px 0;}
    .SlideArea .SlideBox {display: flex; flex-direction: column;}
    .SlideArea .SlideRight {width: 100%;}
    .navbar-nav {margin-top: 25px;}
    .SlideArea .SlideRight {margin: 50px 0 0 0;}
    header .navbar .navbar-nav li {margin: 0 0 15px 0px;}
    .SlideArea .SlideHead .nav-tabs .nav-item .nav-link {width: 155px;}
    .SlideArea .SlideLeft .ExpandBox {display: none;}
    .about-body ul {flex-direction: column;}
    .about-body ul figure{width: 100%;}
    .about-body ul aside {width: 100%;}
    .about-body .about-left.Experience {padding:0 10px 0px 0}
    .missions {text-align: left;}
    .values{padding: 0px 25px;}
    .CommonForm-video ul {padding: 20px 30px;}
    .CommonForm-video aside .missions br{display: none;}
    .CommonForm-video aside .missions h2 {
        padding-bottom: 10px;
    }
    .CommonForm-video aside .missions {
        padding:  0 0 25px 0;
    }
    .video-right{
        width: 100%;
        position: relative;
        overflow: inherit;
        margin: 0px 0 0px 0;
    }
    .video-right video{
        height: auto;
        position: relative;
        right: 0px;
        width: 100%;
    }
    .video-left {
        width: 100%;
        position: relative;
        overflow: inherit;
        margin: 0px 0 0 0;
        top: 0px;
    }
    .video-left video{
        height: auto;
        width: 100%;
        position: relative;
        left: 0;
    }
}

  @media (max-width:768px){
    .SlideArea .SlideHead .nav-tabs .nav-item .nav-link {width: 110px;}
    .SpecificationsArea .SpecificationsBox {padding: 25px 25px;}
    .PartnerArea .PartnerBody article .nav-tabs .nav-item .nav-link{width: 220px;}
    .PartnerLeftShape{width: 100%;}
    .SpecificationsLeftShape {width: 100%;}
    .SlideArea .SlideHead .nav-tabs {
    width: 100%;display: flex;justify-content: space-between;}
    .CommonForm-video ul {padding: 20px 20px;}
}

    @media (max-width:576px){
        .SlideArea .SlideHead .nav-tabs .nav-item .nav-link {width: 110px;}
        .SlideArea {padding: 125px 5px 50px 5px;}
        .SpecificationsArea .SpecificationsBox {padding: 15px 15px;}
        .PartnerArea{ padding: 50px 0px}
    .PartnerArea .PartnerBody article .nav-tabs .nav-item .nav-link{width: 135px;}
    .Breadcumb {padding: 300px 20px 50px 20px;}
    .PartnerArea .PartnerBody article .nav-tabs .nav-item {width: 50%;}
    .PartnerArea .PartnerBody article .nav-tabs .nav-item .nav-link {width: 100%;height: 100%;font-size: 16px;}
    .SlideArea .SlideLeft figure img {width: 100%;}
    header .navbar {padding: 15px 0px;}
    .SlideArea .SlideLeft {padding: 0px;}
    .SpecificationsArea .SpecificationsBox .Specifications p {font-size: 14px;}
    .SpecificationsArea .SpecificationsBox .Specifications p label {width: 100px;margin: 0 0 10px 0;}
    .Breadcumb article h4 {font-size: 38px;}
    .CommonForm-india .india-ride figure img {width: 100%;}
    .about-body ul aside .about-left {padding: 10px;}
    .values{padding: 0px 10px;}
    .CommonForm {padding: 25px 10px 50px;}
    .values ul {margin-left: 20px;}
    .values h2 {font-size: 40px;}
    .values p {font-size: 20px;}
    .about-body .about-left h2 {font-size: 22px; line-height: 40px;}
    .NewsTabs {width: 245px;}
    .CommonForm-video aside .missions h2 {font-size: 22px; line-height: 30px;}
    .CommonForm-video aside .missions br {display: none;}
    .CommonForm-video aside .missions p {font-size: 16px; line-height: 26px;}
    .about-body .about-left p {font-size: 16px; line-height: 26px;}
    .CommonForm-video ul {padding-left: 10px; padding-right: 10px;}
    }
@media (max-width:490px){
    .SlideArea .SlideHead .nav-tabs .nav-item .nav-link {width: 70px;}
}

@media (max-width:472px){
    .CommonForm .form-group ul li {width: 50%;}
    .modal-body article {position: relative;background-color: rgb(0,0,0);}
    .modal-dialog {margin-left: 10px!important;margin-right: 10px!important;}
    .SlideArea .SlideRight article h4 {font-size: 18px;margin-bottom: 0px;}
    .SlideArea .SlideRight article {padding: 10px;}
    .SlideArea .SlideRight article p {font-size: 12px;}
    
}
@media (max-width:472px){.modal-body article {position: relative;background-color: rgb(0,0,0);}
    .modal-dialog {margin-left: 10px!important;margin-right: 10px!important;}
    .SlideArea .SlideRight article h4 {font-size: 18px;margin-bottom: 0px;}
    .SlideArea .SlideRight article {padding: 10px;}
    .SlideArea .SlideRight article p {font-size: 12px;}
}
@media (max-width:576px){
    .values ul li {font-size: 20px;}
    .about-body .about-left h3 {font-size: 24px; line-height: 52px;}
    .CommonForm-india .india-ride figcaption h3 {line-height: 20px;}
    .SpecificationsArea .SpecificationsBox .Specifications h4 {font-size:26px;}
}


  
  